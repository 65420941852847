<template>
    <div class="menu-wrapper" v-if="render">
      <component :is="compName"></component>
      <div class="label">
        <span>{{menName}}</span>
      </div>
    </div>
</template>

<script>
import MenuAnleitung from "./MenuAnleitung";
import MenuIntro from "./MenuIntro";
import MenuLeitfaden from "./MenuGrundlagen";
import MenuSuche from "./MenuSuche";
import MenuLinks from "@/components/animatedMenus/MenuLinks";
import MenuTippClip from "@/components/animatedMenus/MenuTippClip";
import MenuBlackstory from "@/components/animatedMenus/MenuBlackstory";

  export default {
    name: "MenuWrapperSmall",
    components: {
      MenuSuche,
      MenuLeitfaden,
      MenuIntro,
      MenuAnleitung,
      MenuLinks,
      MenuTippClip,
      MenuBlackstory
    },
    data() {
      return {
        render: true
      }
    },
    props: {
      compName: {
        type: String,
        default: ''
      },
      menName: {
        type: String,
        default: 'undefiniert'
      }
    },
    mounted() {
      if (this.compName === '') {
        this.render = false;
      }
    }
  }
</script>

<style scoped lang="scss">

.menu-wrapper {
  margin-right: 5px;
  margin-left: 5px;

  svg {
    height: 90px;
    width: 100px;
    margin-bottom: -20px;
  }

  .label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }

}

</style>