<template>
  <div class="tipp-menu-bar">
    <TippUpDownArrow
        :rotate="menuVisible"
        @toggled="menuVisible = !menuVisible"></TippUpDownArrow>
    <div class="menu-bar-wrapper" :class="{up: menuVisible}" :style="'border-top: 12px solid ' + color + ';'">
      <div class="menu-bar">
        <router-link :to="'/blackstory/' + tippIndex" v-if="blackstoryUrl">
          <MenuWrapperSmall comp-name="MenuBlackstory" men-name="Blackstory"></MenuWrapperSmall>
        </router-link>
        <!-- <router-link :to="'/tippclip/' + tippIndex" v-if="clippTippUrl">
          <MenuWrapperSmall comp-name="MenuTippClip" men-name="Tipp Clip"></MenuWrapperSmall>
        </router-link> -->
        <router-link :to="'/links/' + tippIndex">
          <MenuWrapperSmall comp-name="MenuLinks" :men-name="$t('footer.links')"></MenuWrapperSmall>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TippUpDownArrow from "./TippUpDownArrow";
import data from "@/data/data-tree.js";
import MenuWrapper from "@/components/animatedMenus/MenuWrapper";
import MenuWrapperSmall from "@/components/animatedMenus/MenuWrapperSmall";

export default {
  name: "TippMenuBar",
  components: { TippUpDownArrow, MenuWrapper, MenuWrapperSmall },
  data() {
    return {
      menuVisible: false,
      blackstoryUrl: '',
      clippTippUrl: ''
    }
  },
  props: {
    color: {
      type: String,
      default: 'black'
    },
    tippIndex: {
      type: String
    },
    primaryVisible: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.blackstoryUrl = data['tipps' + this.$i18n.locale][this.$route.params.id].blackstory
    this.clippTippUrl = data['tipps' + this.$i18n.locale][this.$route.params.id].tippClip

    this.$nextTick(() => {
      this.menuVisible = this.primaryVisible;
    })
  },
  watch: {
    primaryVisible: function (newq, oldq) {
      this.menuVisible = !!newq;
    },
  }
}
</script>

<style lang="scss" scoped>

.menu-bar-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 0;
  transition: all 0.4s ease-out;

  &.up {
    height: 120px;
  }

  .menu-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    transition: all 0.4s ease-out;

    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
    }

    @include mobile-down {
      img {
        height: 60px;
      }
    }
  }
}
</style>
