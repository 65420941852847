<template>
  <div class="tipp">
    <img class="close-tip" src="../assets/tipp/tipp-cross.svg" @click="$router.push('/')">
    <div class="tipp-title-name" v-if="!isFront">
      {{this.tippObj.title}}
    </div>
    <div class="tipp-view" v-if="isFront">
      <h1>{{ this.tippObj.title }}</h1>
    </div>
    <div class="tipp-content" v-else>
      <div class="tipp-image">
        <img :src="tipp.image">
      </div>
      <div class="tipp-text">
        <h3>{{ tipp.header }}</h3>
        <p v-for="text in tipp.paragraph">{{ text }}</p>
      </div>
    </div>
    <TippMenuBar
        class="tipp-menu-bar"
        :primary-visible="isFront"
        :color="tippObj.color"
        :tipp-index="$route.params.id"></TippMenuBar>
    <TippArrowNavigation
        class="tipp-navigation"
        :has-previous="!isFront"
        :has-next="!isEnd"
        @previous-clicked="previousPage()"
        @next-clicked="nextPage()"></TippArrowNavigation>
  </div>
</template>

<script>
import data from "@/data/data-tree.js";
import TippArrowNavigation from "../components/TippArrowNavigation";
import TippMenuBar from "../components/TippMenuBar";

export default {
  name: 'Tipp',
  components: {
    TippMenuBar,
    TippArrowNavigation
  },
  data() {
    return {
      tippObj: {},
      isFront: true,
      isEnd: false,
      chapterIndex: 0,
      pageIndex: 0,
      paragraphIndex: 0,
      tipp: {
        image: "/tippgif/200.gif",
        header: "",
        paragraph: []
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.tippObj = data['tipps' + this.$i18n.locale][this.$route.params.id];
    }
    this.isFront = true
  },
  computed: {
    tippGif() {
      return this.data;
    }
  },
  methods: {
    nextPage() {
      if (this.isFront) {
        this.isFront = false;
        this.fillTipp();
        return false;
      }

      if (this.tippObj.content.length === (this.chapterIndex + 1) && this.tippObj.content[this.chapterIndex].pages.length === (this.pageIndex + 1) && this.tippObj.content[this.chapterIndex].pages[this.pageIndex].length === (this.paragraphIndex + 2)) {
        this.isEnd = true
      }

      // is next Paragraph possible?
      if (typeof this.tippObj.content[this.chapterIndex].pages[this.pageIndex][(this.paragraphIndex + 1)] !== "undefined") {
        this.paragraphIndex++;
        this.fillTipp()
        return false;
      } else {
        // if not, is next page possible?
        if (typeof this.tippObj.content[this.chapterIndex].pages[(this.pageIndex + 1)] !== "undefined") {
          this.pageIndex++;
          this.paragraphIndex = 0;
          this.tipp.paragraph = []
          this.fillTipp()
          return false;
        } else {
          //if not, is next chapter possible?
          if (typeof this.tippObj.content[(this.chapterIndex + 1)] !== "undefined") {
            this.chapterIndex++;
            this.pageIndex = 0;
            this.paragraphIndex = 0;
            this.tipp.paragraph = []
            this.fillTipp();
            return false;
          } else {
            // if not, we are at the end
            this.isEnd = true;
          }
        }
      }

    },
    previousPage() {
      if (this.isEnd) {
        this.isEnd = false;
      }

      if (this.chapterIndex == 0 && this.pageIndex == 0 && this.paragraphIndex == 0) {
        this.front = true
      }

      // is next Paragraph possible?
      if (this.paragraphIndex > 0) {
        this.paragraphIndex--;
        this.fillTipp()
        return false;
      } else {
        // if not, is next page possible?
        if (this.pageIndex > 0) {
          this.pageIndex--;
          this.paragraphIndex = this.tippObj.content[this.chapterIndex].pages[this.pageIndex].length - 1;
          this.tipp.paragraph = []
          this.fillTipp()
          return false;
        } else {
          //if not, is next chapter possible?
          if (this.chapterIndex > 0) {
            this.chapterIndex--;
            this.pageIndex = this.tippObj.content[this.chapterIndex].pages.length - 1;
            this.paragraphIndex = this.tippObj.content[this.chapterIndex].pages[this.pageIndex].length - 1;
            this.tipp.paragraph = []
            this.fillTipp();
            return false;
          } else {
            // if not, we are at the end
            this.isFront = true;
            this.tipp.paragraph = []
          }
        }
      }
    },
    fillTipp() {
      this.tipp.header = this.tippObj.content[this.chapterIndex].chapter;


      this.tipp.paragraph = []
      let possiblePara = this.tippObj.content[this.chapterIndex].pages[this.pageIndex]

      for (let i = 0; i < possiblePara.length; i++) {
        if (this.paragraphIndex >= i) {
          this.tipp.paragraph.push(possiblePara[i]);

          if (typeof this.tippObj.content[this.chapterIndex].image === 'object') {
            this.tipp.image = "/tippgif/" + this.tippObj.content[this.chapterIndex].image[this.pageIndex][i] + ".gif";
          } else {
            this.tipp.image = "/tippgif/fallback.gif"
          }
        }
      }

      //this.tipp.paragraph.push(this.tippObj.content[this.chapterIndex].pages[this.pageIndex][this.paragraphIndex])
    },
    removeTipp() {
      this.tipp.header = this.tippObj.content[this.chapterIndex].chapter;
      this.tipp.paragraph.pop()
    }
  },
}
</script>

<style lang="scss" scoped>

.tipp {
  width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;

  @include tablet-down {
    width: 90%;
  }

  .close-tip {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 65px;
    transform: translate(100%);
    cursor: pointer;

    @include mobile-down {
      width: 35px;
      right: 25px;
    }
  }
}

.tipp-title-name {
  position: absolute;
  top: 25px;
  left: 0;
  font-size: 17px;
  font-family: "HKSentiments-bold", sans-serif;

  @include tablet-down {
    top: 70px;
  }
}

.tipp-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  padding-top: 10%;

  @include tablet-down {
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 200px;
  }

  .tipp-image {
    width: 35%;
    position: relative;

    @include tablet-down {
      width: 100%;
      padding: 15px;
    }

    img {
      position: absolute;
      top: 30px;
      left: 0;
      width: 80%;
      object-fit: cover;

      @include tablet-down {
        position: relative;
        width: 70%;
        margin: 0 auto;
        display: block;
      }
    }

  }

  .tipp-text {
    width: 65%;

    @include tablet-down {
      width: 100%;
      padding-top: 40px;
    }

    h3 {
      border-bottom: 3px black solid;
      font-size: 30px;
      width: max-content;
      font-family: "HKSentiments-bold", sans-serif;

      @include tablet-down {
        font-size: 18px;
      }
    }

    p {
      padding-top: 15px;
      padding-bottom: 0;
      font-size: 40px;
      font-family: "HKSentiments-black", sans-serif;

      @include tablet-down {
        font-size: 25px;
      }
    }

    @include desktop-large-down {
      p {
        font-size: 35px;
      }
    }
  }
}

.tipp-view {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  font-family: "HKSentiments-black", sans-serif;

  h1 {
    font-size: 120px;

    @include desktop-large-down {
      font-size: 80px;
    }

    @include mobile-down {
      font-size: 60px;
      margin-top: 90px;
    }
  }
}

.tipp-navigation {
  position: absolute;
  right: 0;
  bottom: 0;

  @include tablet-down {
    bottom: 15%;
  }
}

.tipp-menu-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}


</style>