<template>
    <div class="tipp-up-down-arrow">
        <img class="arrow" src="../assets/tipp/tipp-up.svg" @click="$emit('toggled')" :class="{rotate: rotate}">
    </div>
</template>

<script>
    export default {
        name: "TippUpDownArrow",
        props: {
            rotate: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<style lang="scss" scoped>

    .tipp-up-down-arrow {
        height: 80px;
        cursor: pointer;

        @include mobile-down {
            height: 50px;
        }
    }

    .arrow {
        width: 70px;
        transition: all 0.4s ease-out;

        @include mobile-down {
            width: 40px;
        }

        &.rotate {
            transform: rotate(180deg);
        }
    }

</style>